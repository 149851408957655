import { EvaIcon } from "@paljs/ui/Icon";
import Tooltip from '@paljs/ui/Tooltip';
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import Fetch from "../components/Fetch";
import { Vessel } from "../components/Icon";
import { LoadingCard } from "../components/Loading/LoadingCard";
import TextSpan from "../components/Text/TextSpan";
import { getUserRole } from "../components/Contexts/Auth";

const OrderCard = styled.div`
  height: calc(30% - 1.81rem);
  width: calc(100% - 2rem);
  display: flex;
  border-width: 1px;
  border-color: ${({ theme }) => theme.backgroundBasicColor4};
  border-radius: 4px;
  border-style: solid;
  cursor: pointer;
  position: relative;
  flex-direction: column;
  transition: background-color 0.3s ease;
  flex-shrink: 0;
  margin-top: 1rem;
  padding: 1rem;
  justify-content: space-evenly;
  user-select: none;

  &:hover {
    background-color: ${({ theme }) => theme.colorInfoTransparentDefault};
  }

  &:active {
    background-color: ${({ theme }) => theme.colorPrimaryTransparentActive};
  }
`;

const StyledVessel = styled.svg`
  height: 16px;
  width: 16px;
  fill: ${({ theme }) => theme.borderBasicColor5};
  margin-right: 4px;
`;

const Column = styled.div`
  border-style: solid;
  display: flex;
  background-color: ${({ theme }) => theme.backgroundBasicColor1};
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  min-width: 280px;
  // height: 100%;
  height: calc(100vh - 10rem);
  width: calc(100% / 7);
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;

  &::-webkit-scrollbar {
    width: 8px;
    transition: background-color 0.3s ease;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.borderPrimaryColor1};
    border-radius: 6px;
    transition: background-color 0.3s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.borderPrimaryColor2};
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colorInfoTransparentDisabled};
    border-radius: 6px;
  }

  &::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => theme.colorInfoTransparentDisabled};
  }

`;

const StatusContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.backgroundBasicColor1};
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  user-select: none;
`;

const Container = styled.div`
  height: calc(100vh - 8rem);
  margin-bottom: 0px;
  width: 96vw;
  display: flex;
  flex-direction: row;
  min-width: 200px;
  gap: 1rem;
  scroll-behavior: smooth;
  overflow-y: hidden;


  &::-webkit-scrollbar {
    height: 8px;
    transition: background-color 0.3s ease;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.borderPrimaryColor1};
    border-radius: 6px;
    transition: background-color 0.3s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.borderPrimaryColor2};
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colorInfoTransparentDisabled};
    border-radius: 6px;
  }

  &::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => theme.colorInfoTransparentDisabled};
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const AlertWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 1rem;
`;

const ChevronWrapper = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;

const Tracking = (props) => {

  const theme = useTheme();
  const navigate = useNavigate();


  const [isLoading, setIsLoading] = React.useState(false);
  const [orders, setOrders] = React.useState([]);
  const [userRole, setUserRole] = React.useState();

  React.useEffect(() => {
    setIsLoading(true);
    getData();

    getUserRole().then((role) => {
      setUserRole(role)
    });

    const oneMinute = 60000;
    let interval = setInterval(() => {
      getData();

    }, oneMinute);
    return () => clearInterval(interval);
  }, []);


  const getData = () => {
    Fetch.get("/fas/orders")
      .then((response) => {
        if (response.data && response.data.length) setOrders(response.data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  let defaultCols = [
    "awaiting.collaborators",
    "awaiting.rating",
  ]

  if (userRole?.role === "admin") {
    defaultCols = defaultCols.concat([
      "awaiting.bms",
      "awaiting.bms.confirm",
      "bms.refused",
      "awaiting.sap",
      "awaiting.buy.request",
      "awaiting.invoice",
      "invoice.rejected",
      "awaiting.payment",
      "fas.closed",
    ])
  }

  function renderTitle(status) {
    switch (status) {
      case "fas.closed": return 'OS Fechada';
      case "awaiting.collaborators": return 'Aguardando Seleção de Colaboradores';
      case "awaiting.rating": return "Aguardando Avaliação";
      case "awaiting.bms.confirm": return 'Aguardando confirmação de BMS';
      case "awaiting.buy.request": return 'Aguardando pedido de compra';
      case "awaiting.sap": return 'Aguardando SAP'
      case "bms.refused": return 'BMS Recusada';
      case "awaiting.bms": return 'Aguardando BMS';
      case "awaiting.payment": return 'Aguardando Confirmação de Pagamento';
      case "awaiting.invoice": return 'Aguardando Nota fiscal';
      case "invoice.rejected": return 'Nota Recusada'
      default: break;
    }
  }

  const countItems = (orders, category) => {
    return orders.filter((order) => order.state === category).length;
  };

  return (
    <LoadingCard isLoading={isLoading}>
      <Container>
        {defaultCols.map((status, j) => {
          const itemCount = countItems(orders, status);
          return (
            <Column key={`col-${j}`}>
              <StatusContainer><TextSpan apparence="s3">{renderTitle(status)} ({itemCount})</TextSpan></StatusContainer>

              {orders.map((order, i) => {

                const warning = order.state === "awaiting.collaborators"
                  || order.state === "awaiting.bms"
                  || order.state === "awaiting.invoice"
                  || order.state === "awaiting.payment"
                  || order.state === "invoice.rejected"
                  || order.state === "bms.refused"

                const options = { height: 16, width: 16, color: theme.borderBasicColor5 };

                if (order.state === status) {
                  return (
                    <OrderCard key={`${order.id}-${i}`} onClick={() => navigate(`/order-view?id=${order.id}`)}>

                      <InfoWrapper>
                        <EvaIcon name="settings-outline" options={options} />
                        <TextSpan apparence="s1">{order.name || 'Não informado'}</TextSpan>
                      </InfoWrapper>

                      <InfoWrapper>
                        <EvaIcon name="calendar-outline" options={options} />
                        <TextSpan apparence="s3">
                          {order.fasHeader?.serviceDate ? moment(order.fasHeader?.serviceDate).format("DD MMM YYYY HH:mm") : "-"}
                        </TextSpan>
                      </InfoWrapper>

                      <InfoWrapper>
                        <StyledVessel>
                          <Vessel />
                        </StyledVessel>
                        <TextSpan apparence="s3">{order.fasHeader?.vessel?.name || 'Não informado'}</TextSpan>
                      </InfoWrapper>

                      <InfoWrapper>
                        <EvaIcon name="pin-outline" options={options} />
                        <TextSpan apparence="s3">{order.fasHeader?.local || 'Não informado'}</TextSpan>
                      </InfoWrapper>

                      {!!order.buyRequest && <InfoWrapper>
                        <EvaIcon name="file-text-outline" options={options} />
                        <TextSpan apparence="s3">{order.buyRequest}</TextSpan>
                      </InfoWrapper>}

                      {warning && (
                        <AlertWrapper>
                          <Tooltip
                            eventListener="#scrollPlacementId"
                            className="with-margin inline-block"
                            trigger="hint"
                            placement="left"
                            content="Ação necessária"
                          >
                            <EvaIcon name="alert-triangle-outline" options={{ height: 18, width: 18, color: theme.colorWarning500 }} />
                          </Tooltip>
                        </AlertWrapper>
                      )}

                      <ChevronWrapper>
                        <EvaIcon name="chevron-right" options={{ height: 32, width: 32, color: theme.colorPrimary400 }} />
                      </ChevronWrapper>

                    </OrderCard>
                  )
                }
              })}

            </Column>
          )
        })}
      </Container>
    </LoadingCard>
  );
};

export default Tracking;
