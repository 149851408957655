import { EvaIcon } from "@paljs/ui";
import { Button } from "@paljs/ui/Button";
import { Card, CardBody, CardFooter, CardHeader } from "@paljs/ui/Card";
import Col from "@paljs/ui/Col";
import { InputGroup } from "@paljs/ui/Input";
import Row from "@paljs/ui/Row";
import moment from "moment";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled, { css, useTheme } from "styled-components";
import Fetch from "../components/Fetch";
import { Cash, Numeric, Ruler } from "../components/Icons";
import InputDateTime from "../components/Inputs/InputDateTime";
import InputDecimal from "../components/Inputs/InputDecimal";
import LabelIcon from "../components/Label/LabelIcon";
import { LoadingCard } from "../components/Loading/LoadingCard";
import Modal from "../components/Modal";
import SelectCollaboratorPreloaded from "../components/Select/SelectCollaboratorPreloaded";
import { TABLE, TBODY, TD, TH, THEAD, TR, TRH } from '../components/Table';
import LoadingRows from "../components/Table/LoadingRows";
import TextSpan from "../components/Text/TextSpan";
import { v4 as uuidv4 } from 'uuid';
import { floatToStringExtendDot } from "../components/Utils";
import SelectCollaborator from "../components/Select/SelectCollaborator";
import CollaboratorsTable from "../components/Table/CollaboratorTable";
import UploadFileDisableInput from "../components/UploadFile"

const RowCenter = styled.div`
  display: flex;
  justify-content: center;
`

const AddBms = (props) => {
  const maxSizeFile = process.env.REACT_APP_MAX_SIZE_FILE_BYTES;

  const [data, setData] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get('id');
  const [files, setFiles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedExpense, setSelectedExpense] = React.useState({});
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [selectedExpenseType, setSelectedExpenseType] = React.useState();
  const [expenses, setExpenses] = React.useState([]);
  const [modalVisibility, setModalVisibility] = React.useState(false);
  const [loadedCollaborators, setLoadedCollaborators] = React.useState([]);
  const navigate = useNavigate();

  React.useLayoutEffect(() => {
    getData()
  }, [])

  const onDelete = () => {
    const oldExpenses = expenses;
    const newExpenses = oldExpenses.filter((x, i) => i !== selectedIndex);
    setExpenses(newExpenses);
    setModalVisibility(false);
  }

  const getData = () => {
    if (!orderId) {
      return;
    }
    setIsLoading(true);
    Fetch.get(`fas/orders/find?id=${orderId}`)
      .then(response => {
        setData(response.data ? response.data : [])
        setLoadedCollaborators(
          response.data.collaborators.map((col) => {
            return {
              value: col,
              label: col.name
            }
          })
        )
        if (["awaiting.bms", "bms.refused", "awaiting.bms.confirm"].includes(response.data.state)) {
          const formattedMainExpenses = response.data.bms.main_expenses.map(expense => ({
            ...expense,
            expenseType: "main",
            collaboratorName: expense.collaborator_name,
            date: expense.date,
            startTime: expense.startTime,
            endTime: expense.endTime
          }));

          const formattedOtherExpenses = response.data.bms.other_expenses.map(expense => ({
            ...expense,
            expenseType: "other",
            collaboratorName: expense.collaborator_name,
            date: expense.date,
            startTime: expense.startTime,
            endTime: expense.endTime,
            additionalInfo: expense.additional_info
          }));

          const mainExpenses = formattedMainExpenses || [];
          const otherExpenses = formattedOtherExpenses || [];

          const allExpenses = [...mainExpenses, ...otherExpenses];
          setExpenses(allExpenses)
        }
        setIsLoading(false)
      })
      .catch(e => {
        setIsLoading(false)
      })
  }

  function footerRender() {
    return (
      <div style={{ margin: '1rem' }}>
        <Row between="xs">
          <Row>
            <Button
              size="Tiny"
              status="Basic"
              appearance="ghost"
              className="flex-between ml-2"
              onClick={() => setModalVisibility(false)}
            >
              <EvaIcon name="chevron-left" className="mr-1" />
              Cancelar
            </Button>
            {!!selectedExpense && (
              <Button
                size="Tiny"
                appearance="ghost"
                status="Danger"
                className="flex-between ml-2"
                onClick={() => onDelete()}
              >
                <EvaIcon name="trash-2-outline" className="mr-1" />
                Remover
              </Button>
            )}
          </Row>
          <Button
            size="Tiny"
            className="flex-between mr-2"
            onClick={addOrUpdateExpense}
          >
            <EvaIcon name="checkmark-outline" className="mr-1" />
            Salvar
          </Button>
        </Row>
      </div>
    )
  }

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const fileMoreSize = acceptedFiles.find((x) => x.size > maxSizeFile);
      if (!!fileMoreSize) {
        toast.warn("Arquivo Muito Grande");
        return;
      }
      const filesToAdd = acceptedFiles
      const totalSize = filesToAdd.reduce((a, b) => a + b.size, 0);
      if (totalSize > (maxSizeFile * filesToAdd.length)) {
        toast.warn("Tamanho Máximo de Arquivos Excedido");
        return;
      }
      setFiles(filesToAdd);
    }
  };

  const onRemoveFile = (index) => {
    setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
  };

  const verifyTimeHasTimeZone = (time) => {
    return time.includes("Z") ||
      time.toString()?.length > 19
      ? time
      : `${time}${moment().format("Z")}`;
  }

  const onSave = async () => {
    if (!expenses?.length) {
      toast.warn("Adicione ao menos uma despesa");
      return;
    }
    const bms = {
      id: orderId,
      collaborators: data?.collaborators?.map(x => ({
        ...x,
        AsoExpirationDate: x.AsoExpirationDate ? new Date(x.AsoExpirationDate) : null,
      })),
      bms: {
        ship: data.fasHeader?.vessel?.name,
        main_expenses: expenses
          .filter(expense => expense.expenseType === 'main')
          .map(({ id, expenseType,
            value,
            amount,
            additionalInfo,
            collaboratorName,
            startTime,
            endTime,
            ...rest }) => ({
              ...rest,
              startTime: startTime ? verifyTimeHasTimeZone(startTime) : null,
              endTime: endTime ? verifyTimeHasTimeZone(endTime) : null,
              additional_info: additionalInfo,
              collaborator_name: collaboratorName,
              value: Number(value),
              amount: Number(amount),
            })),
        other_expenses: expenses
          .filter(expense => expense.expenseType === 'other')
          .map(({ id, expenseType,
            value,
            amount,
            additionalInfo,
            collaboratorName,
            startTime,
            endTime,
            ...rest }) => ({
              ...rest,
              startTime: startTime ? verifyTimeHasTimeZone(startTime) : null,
              endTime: endTime ? verifyTimeHasTimeZone(endTime) : null,
              additional_info: additionalInfo ? additionalInfo : "N/A",
              collaborator_name: collaboratorName,
              value: Number(value),
              amount: Number(amount),
            }))
      }
    };

    setIsLoading(true);
    try {
      const response = await Fetch.post("/fas/add-bms", bms);
      if (response.status === 200 && files.length) {
        toast.success("BMS Adicionada com Sucesso");
        const form = new FormData();
        form.append("id", orderId);
        for (const fi of files) {
          form.append("files", fi);
        }
        const filesResponse = await Fetch.post(`/fas/add-bms-files`, form);
      }
      setIsLoading(false);
      navigate(-1);
    } catch (e) {
      setIsLoading(false);
    }
  };

  function handleNewCollaborator(value, index) {
    const newCollaborators = data.collaborators.map((collaborator, idx) => {
      if (idx === index) {
        return { ...collaborator, new: value };
      }
      return collaborator;
    });
    setData({
      ...data,
      collaborators: newCollaborators
    })
  }

  function collaboratorsNeededAndNotFilled() {
    return data?.fasHeader?.type === "Regularizacao" && !data?.collaborators?.length
  }

  function changeCollaborator(selectedCollaborators) {
    setLoadedCollaborators(selectedCollaborators)
    setData({
      ...data,
      collaborators: selectedCollaborators.map(collaborator => collaborator.value),
    })
  }

  function handleModalOpening(expense, type, index = null) {
    const expenseIndex = index ? index : expenses.findIndex((item) => item.expense === expense?.expense);
    if (expenseIndex !== -1) {
      setSelectedIndex(expenseIndex);
    } else {
      setSelectedIndex(expenses.length);
    }

    setModalVisibility(true);
    setSelectedExpense(expense);
    setSelectedExpenseType(type);
  }

  function updateExpenseField(field, value) {
    setSelectedExpense((prevSelectedExpense) => {
      const updatedExpense = { ...prevSelectedExpense };
      updatedExpense[field] = value;
      if (field === "date") {
        updatedExpense.startTime = updatedExpense.startTime ?
          `${value.split("T")[0]}T${selectedExpense.startTime.split("T")[1]}${moment().format("Z")}` : "";
        updatedExpense.endTime = updatedExpense.endTime ?
          `${value.split("T")[0]}T${selectedExpense.endTime.split("T")[1]}${moment().format("Z")}` : "";
      }
      return updatedExpense;
    });
  }

  function addOrUpdateExpense() {
    let updatedExpense = { ...selectedExpense, expenseType: selectedExpenseType };

    if (selectedExpenseType === 'main') {
      if (
        !updatedExpense.date ||
        !updatedExpense.expense ||
        !updatedExpense.startTime ||
        !updatedExpense.endTime ||
        !updatedExpense.collaboratorName ||
        !updatedExpense.role ||
        !updatedExpense.unit ||
        !updatedExpense.value
      ) {
        return toast.error('Campos obrigatórios devem ser preenchidos antes de salvar');
      }
    }

    if (selectedExpenseType === 'other') {
      if (
        !updatedExpense.date ||
        !updatedExpense.expense ||
        !updatedExpense.unit ||
        !updatedExpense.value
      ) {
        return toast.error('Campos obrigatórios devem ser preenchidos antes de salvar');
      }
    }

    if (!updatedExpense.id) {
      updatedExpense.id = uuidv4();
    }

    setExpenses((prevExpenses) => {
      const expenseIndex = prevExpenses.findIndex((expense, index) => index === selectedIndex);

      if (expenseIndex !== -1) {
        const updatedExpenses = [...prevExpenses];
        updatedExpenses[expenseIndex] = updatedExpense;
        return updatedExpenses;
      } else {
        return [...prevExpenses, updatedExpense];
      }
    });

    setModalVisibility(false);
  }

  const theme = useTheme();

  function calculateTotalExpenses(_expenses) {
    const total = _expenses.reduce((accumulator, expense) => {
      const subtotal = parseFloat(expense.value) * parseFloat(expense.amount) || 0;
      return accumulator + subtotal;
    }, 0);

    return total.toFixed(2);
  }

  function renderTotalRow(expenseType) {
    const filteredExpenses = expenses.filter(expense => expense.expenseType === expenseType);

    return filteredExpenses.length > 0 && (
      <TR>
        <TD colspan={expenseType === 'main' ? 9 : 10} textAlign="end">
          <TextSpan apparence='p2' hint>
            Total
          </TextSpan>
          <TextSpan apparence='s1' className="ml-1">
            R$ {floatToStringExtendDot(calculateTotalExpenses(filteredExpenses))}
          </TextSpan>
        </TD>
      </TR>
    );
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Row middle="xs">
            <Button
              size="Small"
              className="flex-between"
              status="Info"
              appearance="ghost"
              onClick={() => navigate(-1)}
            >
              <EvaIcon name="arrow-ios-back-outline" />
              Voltar
            </Button>
            Adicionar BMS
          </Row>

        </CardHeader>
        <CardBody>
          <Row className="mb-4 mt-4">
            <Col breakPoint={{ lg: 3, md: 3 }}>
              <TextSpan apparence="p2" hint>
                OS de Serviço
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.name}
                </TextSpan>
              </InputGroup>
            </Col>
            <Col breakPoint={{ lg: 3, md: 3 }}>
              <TextSpan apparence="p2" hint>
                Empresa:
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.fasHeader?.enterprise?.name}
                </TextSpan>
              </InputGroup>
            </Col>
            <Col breakPoint={{ lg: 3, md: 3 }}>
              <TextSpan apparence="p2" hint>
                Embarcação:
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.fasHeader?.vessel?.name}
                </TextSpan>
              </InputGroup>
            </Col>
            <Col breakPoint={{ lg: 3, md: 3 }}>
              <TextSpan apparence="p2" hint>
                Data do Atendimento:
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.fasHeader?.serviceDate ? moment(data.fasHeader?.serviceDate).format("DD MMM YYYY HH:mm") : ""}
                </TextSpan>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Descrição
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.description}
                </TextSpan>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-4 mt-4">
            <Col breakPoint={{ lg: 3, md: 3 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Local
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.fasHeader?.local}
                </TextSpan>
              </InputGroup>
            </Col>
            {data.requestOrder ?
              <>
                <Col breakPoint={{ lg: 6, md: 6 }} className="mb-4">
                  <TextSpan apparence="p2" hint>
                    Pedido/Requisição de Compra
                  </TextSpan >
                  <InputGroup fullWidth className="mt-1">
                    <TextSpan apparence="s2" className="pl-1">
                      {data.requestOrder}
                    </TextSpan>
                  </InputGroup>
                </Col >
              </> : <></>}
          </Row >
          <Row>
            <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Código do Fornecedor
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.supplierData?.codigoFornecedor}
                </TextSpan>
              </InputGroup>
            </Col>
            <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Razão Social do Fornecedor
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.supplierData?.razao}
                </TextSpan>
              </InputGroup>
            </Col>
            <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Email do Fornecedor
              </TextSpan>
              <div className="pl-3">
                {data?.supplierData?.emails?.map((email) =>
                  <Row key={email} className="m-0">
                    <TextSpan className="pl-1" apparence="s1">
                      {email}
                    </TextSpan>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p1 " hint>
                Seleção de Colaboradores
              </TextSpan>
            </Col>
            <Col breakPoint={{ lg: 12, md: 12 }} className="mb-2">
              <SelectCollaborator isMulti
                dataAtendimento={data?.fasHeader?.serviceDate}
                value={loadedCollaborators}
                onChange={changeCollaborator} />
            </Col>
            <Col breakPoint={{ lg: 12, md: 12 }} className="mb-2">
              {collaboratorsNeededAndNotFilled() ?
                <TextSpan apparence="p2" className="pl-1">
                  * Atenção: Está é uma FAS de regularização, preencha os colaboradores antes de prosseguir com a BMS!
                </TextSpan>
                : <></>}
            </Col>
            <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
              <CollaboratorsTable
                collaborators={data.collaborators}
                onNewCollaborator={(handleNewCollaborator)} />
            </Col>
          </Row>
          <Row>
            <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p1 " hint>
                BMS
              </TextSpan>
            </Col>

            <Card style={{ width: '100%' }}>
              <CardHeader>
                <TextSpan apparence="s1" >
                  Despesas HH
                </TextSpan>
              </CardHeader>
              <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
                {isLoading ?
                  <TABLE>
                    <TBODY>
                      <LoadingRows />
                    </TBODY>
                  </TABLE>
                  :
                  <>
                    {expenses.length > 0 && expenses.some(expense => expense.expenseType === 'main') && (
                      <TABLE>
                        <THEAD>
                          <TRH>
                            <TH textAlign="center">
                              <TextSpan apparence='p2' hint>
                                Data
                              </TextSpan>
                            </TH>
                            <TH textAlign="center">
                              <TextSpan apparence='p2' hint>
                                Horário
                              </TextSpan>
                            </TH>
                            <TH textAlign="start">
                              <TextSpan apparence='p2' hint>
                                Despesa
                              </TextSpan>
                            </TH>
                            <TH textAlign="start">
                              <TextSpan apparence='p2' hint>
                                Colaborador
                              </TextSpan>
                            </TH>
                            <TH textAlign="end">
                              <TextSpan apparence='p2' hint>
                                Vl. Unitário
                              </TextSpan>
                            </TH>
                            <TH textAlign="end">
                              <TextSpan apparence='p2' hint>
                                Qtde.
                              </TextSpan>
                            </TH>
                            <TH textAlign="center">
                              <TextSpan apparence='p2' hint>
                                Unidade
                              </TextSpan>
                            </TH>
                            <TH textAlign="end">
                              <TextSpan apparence='p2' hint>
                                Vl. Total
                              </TextSpan>
                            </TH>
                            <TH textAlign="center">
                            </TH>
                          </TRH>
                        </THEAD>
                        <TBODY>

                          {expenses && expenses?.map((expense, i) => {
                            if (expense.expenseType === 'main')
                              return (
                                <TR key={i} isEvenColor={i % 2 === 0}>
                                  <TD textAlign="center">
                                    <TextSpan apparence='s2'>
                                      {moment(expense.date).format("DD MMM YYYY")}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="center">
                                    <TextSpan apparence='s2'>
                                      {moment(expense.startTime).format("HH:mm")} - {moment(expense.endTime).format("HH:mm")}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="start">
                                    <TextSpan apparence='s2'>
                                      {expense.expense}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="start">
                                    <TextSpan apparence='s2'>
                                      {expense.collaboratorName}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="end">
                                    <TextSpan apparence='s2'>
                                      R$ {floatToStringExtendDot(expense.value || 0)}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="end">
                                    <TextSpan apparence='s2'>
                                      {expense.amount}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="center">
                                    <TextSpan apparence='s2'>
                                      {expense.unit}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="end">
                                    <TextSpan apparence='s2'>
                                      R$ {floatToStringExtendDot(parseFloat(expense.value) * parseFloat(expense.amount) || 0)}
                                    </TextSpan>
                                  </TD>

                                  <TD textAlign="center">
                                    <Button
                                      size="Tiny"
                                      appearance="ghost"
                                      onClick={() => handleModalOpening(expense, expense.expenseType, i)}
                                    >
                                      <EvaIcon name="edit-2-outline" />
                                    </Button>
                                  </TD>
                                </TR>
                              )
                          })}
                          {renderTotalRow('main')}
                        </TBODY>
                      </TABLE>
                    )}
                    <RowCenter>
                      <Button
                        size="Tiny"
                        className="flex-between mt-8"
                        appearance="ghost"
                        disabled={collaboratorsNeededAndNotFilled()}
                        onClick={() => handleModalOpening(0, 'main')}
                      >
                        <EvaIcon name="plus-square-outline" className="mr-1" />
                        Adicionar Despesa
                      </Button>
                    </RowCenter>
                  </>
                }
              </Col >
            </Card >

            <Card style={{ width: '100%' }}>
              <CardHeader>
                <TextSpan apparence="s1" >
                  Outras Despesas
                </TextSpan>
              </CardHeader>
              <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
                {isLoading ?
                  <TABLE>
                    <TBODY>
                      <LoadingRows />
                    </TBODY>
                  </TABLE>
                  :
                  <>
                    {expenses.length > 0 && expenses.some(expense => expense.expenseType === 'other') && (
                      <TABLE>
                        <THEAD>
                          <TRH>
                            <TH textAlign="center">
                              <TextSpan apparence='p2' hint>
                                Data
                              </TextSpan>
                            </TH>
                            <TH textAlign="center">
                              <TextSpan apparence='p2' hint>
                                Horário
                              </TextSpan>
                            </TH>
                            <TH textAlign="start">
                              <TextSpan apparence='p2' hint>
                                Despesa
                              </TextSpan>
                            </TH>
                            <TH textAlign="start">
                              <TextSpan apparence='p2' hint>
                                Informações Adicionais
                              </TextSpan>
                            </TH>
                            <TH textAlign="start">
                              <TextSpan apparence='p2' hint>
                                Colaborador
                              </TextSpan>
                            </TH>
                            <TH textAlign="end">
                              <TextSpan apparence='p2' hint>
                                Vl. Unitário
                              </TextSpan>
                            </TH>
                            <TH textAlign="end">
                              <TextSpan apparence='p2' hint>
                                Qtde.
                              </TextSpan>
                            </TH>
                            <TH textAlign="center">
                              <TextSpan apparence='p2' hint>
                                Unidade
                              </TextSpan>
                            </TH>
                            <TH textAlign="end">
                              <TextSpan apparence='p2' hint>
                                Vl. Total
                              </TextSpan>
                            </TH>
                            <TH textAlign="center">
                            </TH>

                          </TRH>
                        </THEAD>
                        <TBODY>
                          {expenses?.map((expense, i) => {
                            if (expense.expenseType === 'other')
                              return (
                                <TR key={i} isEvenColor={i % 2 === 0}>
                                  <TD textAlign="center">
                                    <TextSpan apparence='s2'>
                                      {moment(expense.date,).format("DD MMM YYYY")}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="center">
                                    <TextSpan apparence='s2'>
                                      {expense.startTime && expense.endTime
                                        ? moment(expense.startTime).format("HH:mm") + " - " + moment(expense.endTime).format("HH:mm")
                                        : "-"
                                      }
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="start">
                                    <TextSpan apparence='s2'>
                                      {expense.expense}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="start">
                                    <TextSpan apparence='s2'>
                                      {expense.additionalInfo}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="start">
                                    <TextSpan apparence='s2'>
                                      {expense.collaboratorName}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="end">
                                    <TextSpan apparence='s2'>
                                      R$ {floatToStringExtendDot(expense.value || 0)}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="end">
                                    <TextSpan apparence='s2'>
                                      {expense.amount}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="center">
                                    <TextSpan apparence='s2'>
                                      {expense.unit}
                                    </TextSpan>
                                  </TD>
                                  <TD textAlign="end">
                                    <TextSpan apparence='s2'>
                                      R$ {floatToStringExtendDot(parseFloat(expense.value) * parseFloat(expense.amount) || 0)}
                                    </TextSpan>
                                  </TD>
                                  <TD>
                                    <Button
                                      size="Tiny"
                                      appearance="ghost"
                                      onClick={() => handleModalOpening(expense, expense.expenseType, i)}
                                    >
                                      <EvaIcon name="edit-2-outline" />
                                    </Button>
                                  </TD>
                                </TR>
                              )
                          })}
                          {renderTotalRow('other')}
                        </TBODY>
                      </TABLE>
                    )}
                  </>
                }
                <RowCenter>
                  <Button size="Tiny"
                    appearance="ghost"
                    className="flex-between mt-8"
                    disabled={collaboratorsNeededAndNotFilled()}
                    onClick={() => handleModalOpening({}, 'other')}
                  >
                    <EvaIcon name="plus-square-outline" className="mr-1" />
                    Adicionar Outra Despesa
                  </Button>
                </RowCenter>
              </Col>

            </Card>

          </Row >
          <Row className="mb-4">
            <Col>
              <TextSpan apparence="p2" hint>
                Anexos
              </TextSpan>
              <UploadFileDisableInput
                onAddFiles={onDrop}
                files={files}
                onRemoveFile={onRemoveFile}
                accept={{
                  'image/png': ["png"],
                  'image/jpeg': ["jpg", "jpeg"],
                  'application/pdf': ["pdf"],
                  'application/vnd.ms-excel': ["xls"],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ["xlsx"],
                  'text/csv': ["csv"],
                  'video/mp4': ["mp4"],
                  'video/mpeg': ["mpeg"],
                }}
              />
              <TextSpan apparence="p2" hint>
                *Apenas arquivos PDF/PNG/JPG/JPEG/MP4/MPEG/XLS/XLSX de até 25 MB limitado a 10 arquivos
              </TextSpan>
            </Col>
          </Row>
        </CardBody >
        <CardFooter>
          <Row end="xs">
            <Button
              size="Tiny"
              className="flex-between mr-2"
              disabled={collaboratorsNeededAndNotFilled()}
              onClick={onSave}
            >
              <EvaIcon name="checkmark-outline" className="mr-1" />
              Salvar
            </Button>
          </Row>
        </CardFooter >
      </Card >
      <LoadingCard isLoading={isLoading} />

      <Modal
        show={modalVisibility}
        onClose={() => setModalVisibility(false)}
        size="Large"
        title="Cadastro de Despesa"
        renderFooter={footerRender}
        styleContent={{ overflowX: 'hidden' }}
      >
        <Row className="m-0">
          <Col breakPoint={{ md: 6 }} className="mb-4">
            <LabelIcon iconName="calendar-outline" title="Data" mandatory />
            <InputDateTime onlyDate
              onChange={(e) => updateExpenseField("date", moment(e).format('YYYY-MM-DDTHH:mm:ssZ'))}
              value={selectedExpense.date}
            />
          </Col>
          <Col breakPoint={{ md: 3 }} className="mb-4">
            <LabelIcon iconName="clock-outline" title="Hora Inicio" mandatory={selectedExpenseType === 'main'} />
            <InputDateTime
              onlyTime
              onChange={(e) => updateExpenseField("startTime", (selectedExpense.date.split("T")[0] + "T" + moment(e).format("HH:mm:ss")))}
              value={selectedExpense.startTime}
              isDisabled={!selectedExpense.date}
            />
          </Col>
          <Col breakPoint={{ md: 3 }} className="mb-4">
            <LabelIcon iconName="clock-outline" title="Hora Fim" mandatory={selectedExpenseType === 'main'} />
            <InputDateTime
              onlyTime
              onChange={(e) => updateExpenseField("endTime", (selectedExpense.date.split("T")[0] + "T" + moment(e).format("HH:mm:ss")))}
              value={selectedExpense.endTime}
              isDisabled={!selectedExpense.date}
            />
          </Col>
          <Col breakPoint={{ md: 12 }} className="mb-4">
            <LabelIcon iconName="file-outline" title="Despesa" mandatory />
            <InputGroup fullWidth>
              <textarea
                rows={2}
                value={selectedExpense.expense}
                onChange={(e) => updateExpenseField("expense", e.target.value)}
              />
            </InputGroup>
          </Col>
          {selectedExpenseType === 'other' && (
            <Col breakPoint={{ md: 12 }} className="mb-4">
              <LabelIcon iconName="file-outline" title="Informações Adicionais" />
              <InputGroup fullWidth>
                <textarea
                  rows={1}
                  value={selectedExpense.additionalInfo}
                  onChange={(e) => updateExpenseField("additionalInfo", e.target.value)}
                />
              </InputGroup>
            </Col>
          )}

          <Col breakPoint={{ md: 6 }} className="mb-4">
            <LabelIcon iconName="person-outline" title="Colaborador" mandatory={selectedExpenseType === 'main'} />
            <SelectCollaboratorPreloaded
              dataAtendimento={data.fasHeader?.serviceDate}
              style={{ width: "100%" }}
              orderCollaborators={data?.collaborators}
              value={{ name: selectedExpense.collaboratorName, role: selectedExpense.role }}
              onChange={(e) => {
                console.log(e.value)
                updateExpenseField("collaboratorName", e.value.name)
                updateExpenseField("role", e.value.role)
              }}
            />
          </Col>

          <Col breakPoint={{ md: 6 }} className="mb-4">
            <LabelIcon
              iconName="shield-outline"
              title="Função"
            />
            <div className="mt-1"></div>
            <TextSpan apparence="s1" hint className="pl-1 mt-3">
              {selectedExpense.role || 'Selecione o Colaborador'}
            </TextSpan>
          </Col>

          <Col breakPoint={{ md: 3 }} className="mb-4">
            <LabelIcon
              mandatory
              renderIcon={() =>
                <Cash style={{
                  height: 18,
                  width: 18,
                  fill: theme.borderBasicColor5,
                  marginRight: 4
                }} />
              }
              title="Valor unitário"
            />
            <InputGroup fullWidth>
              <InputDecimal
                onChange={(value) => updateExpenseField("value", value)}
                value={selectedExpense.value}
              />
            </InputGroup>
          </Col>

          <Col breakPoint={{ md: 3 }} className="mb-4">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LabelIcon
                mandatory
                renderIcon={() =>
                  <Numeric style={{
                    height: 18,
                    width: 18,
                    fill: theme.borderBasicColor5,
                    marginRight: 4
                  }} />
                }
                title="Quantidade"
              />
            </div>
            <InputGroup fullWidth>
              <InputDecimal
                mandatory
                onChange={(value) => updateExpenseField("amount", value)}
                value={selectedExpense.amount}
                disabled />
            </InputGroup>
          </Col>

          <Col breakPoint={{ md: 3 }} className="mb-4">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LabelIcon
                mandatory
                renderIcon={() =>
                  <Ruler style={{
                    height: 18,
                    width: 16,
                    fill: theme.borderBasicColor5,
                    marginRight: 4
                  }} />
                }
                title="Unidade"
              />
            </div>
            <InputGroup fullWidth>
              <input
                type="text"
                value={selectedExpense.unit}
                onChange={(e) => updateExpenseField("unit", e.target.value)}
              />
            </InputGroup>
          </Col>

          <Col breakPoint={{ md: 3 }} className="mb-4">
            <div style={{ height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              <LabelIcon
                title="Valor total"
                renderIcon={() =>
                  <Cash
                    style={{
                      height: 18,
                      width: 18,
                      fill: theme.borderBasicColor5,
                      marginRight: 4
                    }}
                  />
                }
              />
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', }}>
                {(!!selectedExpense.value && !!selectedExpense.amount) ? (
                  <TextSpan apparence="h5">R$ {floatToStringExtendDot(parseFloat(selectedExpense.value) * parseFloat(selectedExpense.amount))}</TextSpan>
                ) : (
                  <TextSpan apparence="h5">R$ 0,00</TextSpan>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default AddBms
