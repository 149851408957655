import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@paljs/ui/Button";
import { EvaIcon } from "@paljs/ui/Icon";
import { Card, CardBody, CardHeader, CardFooter } from "@paljs/ui/Card";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import moment from "moment";
import { toast } from "react-toastify";
import { InputGroup } from "@paljs/ui/Input";
import { LoadingCard } from "../components/Loading/LoadingCard";
import TextSpan from "../components/Text/TextSpan";
import RefusalReasonField from "../components/Text/RefusalReasonField";
import { FasSpanOrderDescription } from "../components/Text/FasSpanOrderDescription";
import Fetch from "../components/Fetch";
import { TABLE, TBODY, TD, TH, THEAD, TR, TRH } from '../components/Table';
import LoadingRows from "../components/Table/LoadingRows";
import { CardUploadFile } from "../components/Cards/Upload";
import StatusFas from "./StatusFas";
import BmsExpensesTable from "../components/BmsTable";
import { getUserRole } from "../components/Contexts/Auth";

const OrderView = (props) => {
  const CANNOT_CANCEL = [
    "fas.closed",
    "awaiting.payment",
    "awaiting.invoice",
    "invoice.rejected",
    "awaiting.bms.confirm",
    "awaiting.buy.request",
    "bms.refused",
    "awaiting.sap",
    "awaiting.bms"];
  const [data, setData] = React.useState([])
  const [cancelReason, setCancelReason] = React.useState();
  const [showCancelReasonField, setShowCancelReasonField] = React.useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userRole, setUserRole] = React.useState();
  const orderId = searchParams.get('id');
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
    getUserRole().then((role) => {
      setUserRole(role)
    });
  }, [])

  const downloadInvoice = async (orderId) => {
    Fetch.get(`/invoice/invoice?id=${orderId}`)
      .then(response => {
        window.location.href = response.data?.invoiceUrl
      })
      .catch(e => {
      })
  }

  const getData = () => {
    if (!orderId) {
      console.error("Erro ao buscar ordem");
      return;
    }
    setIsLoading(true);
    Fetch.get(`fas/orders/find?id=${orderId}`)
      .then(response => {
        const formattedBMS = {
          refusalReason: response.data?.bms?.refusalReason,
          started: response.data?.bms?.started
        };
        formattedBMS.main_expenses = response.data?.bms?.main_expenses?.map((expense) => {
          return {
            ...expense,
            date: moment(expense.date).format('DD/MM/YYYY'),
            startTime: moment(expense.startTime).format("HH:mm"),
            endTime: moment(expense.endTime).format("HH:mm"),
          }
        });
        formattedBMS.other_expenses = response.data?.bms?.other_expenses?.map((expense) => {
          return {
            ...expense,
            date: moment(expense.date).format('DD/MM/YYYY'),
            startTime: expense.startTime ? moment(expense.startTime).format("HH:mm") : "-",
            endTime: expense.endTime ? moment(expense.endTime).format("HH:mm") : "-",
          }
        });
        setData(response.data ? { ...response.data, bms: formattedBMS } : [])
        setIsLoading(false);
      })
      .catch(e => {
        setIsLoading(false);
      })
  }

  const cancelOrder = () => {
    if (!orderId) {
      toast.error("Erro ao cancelar ordem");
      return;
    }

    if (!cancelReason) {
      toast.error("É necessário informar o motivo do cancelamento");
      return;
    }
    setIsLoading(true);
    Fetch.post(`fas/cancel`, { id: orderId, supplierRejectReason: cancelReason })
      .then(response => {
        if (response.status === 200) {
          toast.success("Ordem cancelada com sucesso");
        }
        else {
          toast.error("Erro ao cancelar ordem");
        }
        navigate(`/os-panel`);
      })
      .catch(e => {
        setIsLoading(false);
        toast.error("Erro ao cancelar ordem");
      })
  }

  const onCancelReasonField = async () => {
    setCancelReason("");
    setShowCancelReasonField(false);
  }

  const sendBmsToIotLog = async () => {
    try {
      setIsLoading(true);
      const reponse = await Fetch.get(`/fas/send-bms?id=${orderId}`);
      await getData();
    } catch (e) {
      setIsLoading(false);
    }
  }

  const onRemoveFile = async (index) => {
    try {
      setIsLoading(true);
      const fileToDelete = data.files[index];
      const deleteFilePayload = {
        id: orderId,
        fileName: fileToDelete.name,
      }
      const response = await Fetch.post(`/fas/delete-order-attachment`, deleteFilePayload);

      if (response.status === 200) {
        // remove arquivo do array
        const newFiles = data.files.filter((_, i) => i !== index);
        setData({ ...data, files: newFiles });
      } else {
        toast.error("Erro ao excluir arquivo");
      }
      setIsLoading(false);
      toast.success("Arquivo excluído com sucesso");
    } catch (error) {
      setIsLoading(false);
      toast.error("Erro ao excluir arquivo");
    }

  }

  const onDownloadFile = async (index) => {
    try {
      const response = await Fetch.get(`/fas/presignedbylocation?location=${data.files[index].location}`);
      if (response.data.invoiceUrl) {
        window.open(response.data.invoiceUrl);
      } else {
        toast.error("Erro ao baixar arquivo");
      }
    } catch (error) {
      toast.error("Erro ao baixar arquivo");
    }
  }

  const todayIsBeforeDayTwenty = new Date().getDate() <= 20

  return (
    <>
      <Card>
        <CardHeader>
          <Row className="m-0" style={{ flexWrap: `nowrap` }} between="xs" middle="xs">
            <Button
              size="Small"
              className="flex-between"
              status="Info"
              appearance="ghost"
              onClick={() => navigate(-1)}
            >
              <EvaIcon name="arrow-ios-back-outline" />
              Voltar
            </Button>
            <div style={{ width: `100%` }} className="pl-2 pr-2">
              {data.name}
            </div>
            <StatusFas status={data.state} end />
          </Row>
        </CardHeader>
        <CardBody>
          <Row className="mb-4 mt-4">
            <Col breakPoint={{ lg: 3, md: 3 }}>
              <TextSpan apparence="p2" hint>
                OS de Serviço:
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.name}
                </TextSpan>
              </InputGroup>
            </Col>
            <Col breakPoint={{ lg: 3, md: 3 }}>
              <TextSpan apparence="p2" hint>
                Empresa:
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.fasHeader?.enterprise?.name}
                </TextSpan>
              </InputGroup>
            </Col>
            <Col breakPoint={{ lg: 3, md: 3 }}>
              <TextSpan apparence="p2" hint>
                Embarcação:
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.fasHeader?.vessel?.name}
                </TextSpan>
              </InputGroup>
            </Col>
            <Col breakPoint={{ lg: 3, md: 3 }}>
              <TextSpan apparence="p2" hint>
                Data do Atendimento:
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.fasHeader?.serviceDate ? moment(data.fasHeader?.serviceDate).format("DD MMM YYYY HH:mm") : ""}
                </TextSpan>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-4 mt-4">
            <Col breakPoint={{ lg: 3, md: 3 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Local
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.fasHeader?.local}
                </TextSpan>
              </InputGroup>
            </Col>
            {data.requestOrder ?
              <>
                <Col breakPoint={{ lg: 3, md: 3 }} className="mb-4">
                  <TextSpan apparence="p2" hint>
                    Pedido/Requisição de Compra
                  </TextSpan>
                  <InputGroup fullWidth className="mt-1">
                    <TextSpan apparence="s2" className="pl-1">
                      {data.requestOrder}
                    </TextSpan>
                  </InputGroup>
                </Col>
              </> : <></>}
            {data.buyRequest ?
              <>
                <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
                  <TextSpan apparence="p2" hint style={{ marginRight: "10px" }}>
                    Pedido de compra
                  </TextSpan>
                  <InputGroup fullWidth className="mt-1">
                    <TextSpan apparence="s2" className="pl-1">
                      {data.buyRequest}
                    </TextSpan>
                  </InputGroup>
                </Col>
              </> : <></>}
          </Row>
          <Row className="mb-4 mt-4">
            <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Descrição
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <FasSpanOrderDescription text={data.description} apparence="s2" className="pl-1" />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Código do Fornecedor
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.supplierData?.codigoFornecedor}
                </TextSpan>
              </InputGroup>
            </Col>
            <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Razão Social do Fornecedor
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.supplierData?.razao}
                </TextSpan>
              </InputGroup>
            </Col>
            <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Email do Fornecedor
              </TextSpan>
              <div className="pl-3">
                {data?.supplierData?.emails?.map((email) =>
                  <Row key={email} className="m-0">
                    <TextSpan className="pl-1" apparence="s1">
                      {email}
                    </TextSpan>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          {showCancelReasonField &&
            <Row>
              <RefusalReasonField
                onChange={setCancelReason}
                onRefuse={cancelOrder}
                onCancel={onCancelReasonField}
              />
            </Row>
          }
          {!!data.collaborators?.length ?
            isLoading ?
              <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
                <TextSpan apparence="p2" hint>
                  Informações de Colaboradores
                </TextSpan>
                <TABLE>
                  <TBODY>
                    <LoadingRows />
                  </TBODY>
                </TABLE>
              </Col>
              :
              <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
                <TextSpan apparence="p2" hint>
                  Informações de Colaboradores
                </TextSpan>
                <TABLE>
                  <THEAD>
                    <TRH>
                      <TH textAlign="center" key={`col-Zw-1`}>
                        <TextSpan apparence="p2" hint>
                          Código do Colaborador
                        </TextSpan>
                      </TH>
                      <TH textAlign="center" key={`col-Zw-1`}>
                        <TextSpan apparence="p2" hint>
                          Nome
                        </TextSpan>
                      </TH>
                      <TH textAlign="center" key={`col-Zw-1`}>
                        <TextSpan apparence="p2" hint>
                          Função
                        </TextSpan>
                      </TH>
                      <TH textAlign="center" key={`col-Zw-1`}>
                        <TextSpan apparence="p2" hint>
                          ASO
                        </TextSpan>
                      </TH>
                      <TH textAlign="center" key={`col-Zw-1`}>
                        <TextSpan apparence="p2" hint>
                          Data de Expiração da ASO
                        </TextSpan>
                      </TH>
                      <TH textAlign="center" key={`col-Zw-1`}>
                        <TextSpan apparence="p2" hint>
                          Válido
                        </TextSpan>
                      </TH>
                      <TH textAlign="center" key={`col-Zw-1`}>
                        <TextSpan apparence="p2" hint>
                          Novo
                        </TextSpan>
                      </TH>
                    </TRH>
                  </THEAD>
                  <TBODY>
                    {data?.collaborators?.map(({ _id, ...collaborator }, i) => <TR key={i} isEvenColor={i % 2 === 0} >
                      {Object.values(collaborator).map((column, j) =>
                        <TD key={`col-fWt-${collaborator}-${column}`} textAlign="center">
                          <TextSpan apparence="s2" className="pl-1">{
                            typeof (column) === "boolean"
                              ? column
                                ? "Sim"
                                : "Não"
                              : j === 4 && !!column
                                ? moment(column).format("DD MMM YYYY")
                                : column}</TextSpan>
                        </TD>
                      )}
                    </TR>)}
                  </TBODY>
                </TABLE>
              </Col>
            : <></>}
          <BmsExpensesTable
            bmsData={data?.bms}
            isLoading={isLoading}
          />
          {data.files?.length ?
            <>
              <Col>
                <TextSpan apparence="p2" hint>
                  Anexos
                </TextSpan>
                <Row style={{ marginTop: 20 }}>
                  {data.files.map((file, i) => (
                    <Col key={i} breakPoint={{ md: 2 }}>
                      <CardUploadFile
                        file={{
                          path: file.name,
                          size: file.size,
                        }}
                        download={true}
                        deleteButton={
                          !["awaiting.invoice", "awaiting.payment", "fas.closed"].includes(data.state) &&
                          !!file?.uploadedBy?.supplier
                        }
                        onDownloadFile={() => onDownloadFile(i)}
                        onRemoveFile={() => onRemoveFile(i)}
                        isLoading={false}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </> : <></>
          }

          {!!data.bms?.refusalReason ?
            <>
              <Row>
                <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
                  <TextSpan apparence="p2" hint>
                    Motivo da Recusa da BMS
                  </TextSpan>
                  <InputGroup fullWidth className="mt-1">
                    <TextSpan apparence="s2" className="pl-1">
                      {data.bms?.refusalReason}
                    </TextSpan>
                  </InputGroup>
                </Col>
              </Row>
            </>
            : <></>}

          {!!data?.rejectInvoiceReason?.length ?
            <>
              <Row>
                <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
                  <TextSpan apparence="p2" hint>
                    Motivo da Recusa da Nota Fiscal
                  </TextSpan>
                  <ol style={{ marginTop: 0 }}>
                    {data?.rejectInvoiceReason.reverse().map((reason, i) => (
                      <li key={i} className="mb-1">
                        <TextSpan apparence="s1" className="pl-1">
                          {reason}
                        </TextSpan>
                      </li>
                    ))}
                  </ol>
                </Col>
              </Row>
            </>
            : <></>}

          {data.paymentDate ?
            <>
              <TextSpan apparence="p2" hint>
                Data de Pagamento
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.paymentDate}
                </TextSpan>
              </InputGroup>
            </> : <></>}
        </CardBody>
        <CardFooter>
          <Row between="xs" style={{ margin: 0 }}>
            {!CANNOT_CANCEL.includes(data.state) ?
              <Button
                className="flex-between"
                status="Danger"
                size="Tiny"
                appearance="ghost"
                onClick={() => setShowCancelReasonField(true)}
              >
                <EvaIcon name="close-outline" className="mr-1" />
                Cancelar Ordem
              </Button>
              : <><div></div></>}
            <Row style={{ margin: 0 }}>
              {data.state === "awaiting.collaborators" ?
                <Button
                  size="Tiny"
                  className="flex-between mr-2"
                  onClick={() => navigate(`/add-collaborators?id=${orderId}`)}>
                  <EvaIcon name="people-outline" className="mr-1" />
                  Adicionar Colaboradores
                </Button>
                : <></>}

              {["awaiting.bms", "awaiting.rating"].includes(data.state) && data.collaborators.length ?
                <Button size="Tiny"
                  className="flex-between mr-1"
                  appearance="ghost"
                  status="Basic"
                  onClick={() => navigate(`/add-collaborators?id=${orderId}`)}>
                  <EvaIcon name="edit-outline" className="mr-1" />
                  Editar Colaboradores
                </Button>
                : <></>}

              {data.state === "awaiting.bms" ?
                <>
                  <Button size="Tiny"
                    className="flex-between mr-2 ml-2"
                    onClick={() => navigate(`/add-bms?id=${orderId}`)}>
                    <EvaIcon name="plus-square-outline" className="mr-1" />
                    Adicionar BMS
                  </Button>
                  {data?.bms?.started &&
                    < Button
                      size="Tiny"
                      status="Success"
                      className="flex-between mr-2"
                      onClick={sendBmsToIotLog}
                    >
                      <EvaIcon name="arrow-ios-forward-outline" className="mr-1" />
                      Enviar BMS
                    </Button>
                  }
                </>
                : <></>}

              {["bms.refused", "awaiting.bms.confirm"].includes(data.state) ?
                <Button size="Tiny"
                  className="flex-between mr-1"
                  onClick={() => navigate(`/add-bms?id=${orderId}`)}>
                  <EvaIcon name="plus-square-outline" className="mr-1" />
                  Editar BMS
                </Button>
                : <></>
              }
              {["awaiting.invoice", "invoice.rejected"].includes(data?.state) ?
                (data.invoiceS3Location && !data.rejectInvoiceReason) ? <></> :
                  <Col>
                    <Button
                      size="Tiny"
                      className="flex-between mr-1"
                      disabled={!todayIsBeforeDayTwenty}
                      onClick={() => navigate(`/add-invoice?id=${orderId}`)}>
                      <EvaIcon name="file-add-outline" className="mr-1" />
                      Adicionar Nota Fiscal
                    </Button>
                  </Col>
                : <></>}
              {data.state === "awaiting.payment" ?
                data.invoiceS3Location ?
                  <Button size="Tiny"
                    status="Basic"
                    className="flex-between mr-1"
                    onClick={() => downloadInvoice(orderId)}>
                    <EvaIcon name="download-outline" className="mr-1" />
                    Baixar Nota Fiscal
                  </Button>
                  : <></>
                : <></>}
            </Row>
          </Row>
          {!todayIsBeforeDayTwenty && data.state === "awaiting.invoice" &&
            <Row end="xs" className="mt-1">
              <TextSpan apparence="p2" hint>
                * Não é possível enviar a nota fiscal após o dia 20.
                Enviar entre o dia 1 e 20 de cada mês. Por favor, encaminhar no próximo dia 1.
              </TextSpan>
            </Row>}
        </CardFooter>
      </Card>
      <LoadingCard isLoading={isLoading} />
    </>
  )

}

export default OrderView;
